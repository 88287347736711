import React from "react";
import { Tab } from "@headlessui/react";
import cn from "classnames";

import { MODEL_TYPES, LANGUAGES } from "../constants";

interface Props {
  processing: boolean;
  captions: Array<{
    started_at: string;
    user: string;
    transcription: string;
  }>;
  transcription: string;
  execution_time: number;
  model_type: string;
  language: string;
}

type Option = {
  text: string;
  value: string;
};

function TranscriptionResults({
  processing,
  captions,
  transcription,
  execution_time,
  model_type,
  language,
}: Props) {
  const selectedLanguage = (
    LANGUAGES.find((option) => option.value === language) as Option
  ).text;
  const selectedModel = (
    MODEL_TYPES.find((option) => option.value === model_type) as Option
  ).text;

  if (processing) {
    return (
      <>
        <div className="flex justify-between items-center mb-4">
          <h1 className=" font-bold">Transcription Results</h1>
          <div className="space-x-4 flex items-center">
            <div>
              <span className="text-xs mr-1 font-bold">Model Type:</span>
              <span className="text-xs">{selectedModel}</span>
            </div>

            <div>
              <span className="text-xs mr-1 font-bold">Language:</span>
              <span className="text-xs">{selectedLanguage}</span>
            </div>
          </div>
        </div>
        <div className="mb-2">Processing selected file...</div>
      </>
    );
  }

  return transcription ? (
    <>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold">Transcription Results</h1>

        <div className="space-x-4 flex items-center">
          <div>
            <span className="text-xs mr-1 font-bold">Execution time:</span>
            <span className="text-xs">
              {Math.round(execution_time * 100) / 100} seconds
            </span>
          </div>

          <div>
            <span className="text-xs mr-1 font-bold">Model Type:</span>
            <span className="text-xs">{selectedModel}</span>
          </div>

          <div>
            <span className="text-xs mr-1 font-bold">Language:</span>
            <span className="text-xs">{selectedLanguage}</span>
          </div>
        </div>
      </div>

      <Tab.Group>
        <Tab.List className="mb-4 border-b-2 space-x-2">
          <Tab
            className={({ selected }) =>
              cn(
                "py-2 px-4 text-sm font-medium rounded-t focus:outline-none",
                selected
                  ? "bg-indigo-600 text-white"
                  : "text-indigo-600 bg-white"
              )
            }
          >
            Captions
          </Tab>
          <Tab
            className={({ selected }) =>
              cn(
                "py-2 px-4 text-sm font-medium rounded-t focus:outline-none",
                selected
                  ? "bg-indigo-600 text-white"
                  : "text-indigo-600 bg-white"
              )
            }
          >
            Text
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {captions.map((caption, index) => (
              <div key={index} className="mb-6 w-full">
                <div className="space-x-4 flex items-center mb-2">
                  <div>
                    <span className="text-xs mr-1 font-bold">User:</span>
                    <span className="text-xs">{caption.user}</span>
                  </div>

                  <div>
                    <span className="text-xs mr-1 font-bold">Started at:</span>
                    <span className="text-xs">{caption.started_at}</span>
                  </div>
                </div>

                <div className="p-4 border rounded bg-gray-50 w-full font-mono">
                  {caption.transcription}
                </div>
              </div>
            ))}
          </Tab.Panel>
          <Tab.Panel>
            <div className="p-4 border rounded bg-gray-50 w-full font-mono">
              {transcription}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  ) : (
    <h1>Select an audio file and transcribe it to get results here</h1>
  );
}

export default TranscriptionResults;
