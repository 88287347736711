export const LANGUAGES = [
  {
    value: "",
    text: "Auto detect",
  },
  {
    value: "English",
    text: "English",
  },
  {
    value: "Greek",
    text: "Greek",
  },
];

export const MODEL_TYPES = [
  {
    value: "tiny",
    text: "Tiny",
  },
  {
    value: "base",
    text: "Base",
  },
  {
    value: "small",
    text: "Small",
  },
  {
    value: "medium",
    text: "Medium",
  },
  {
    value: "large",
    text: "Large",
  },
];
