import React from "react";
import { useDropzone } from "react-dropzone";
import { filesize } from "filesize";
import cn from "classnames";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";

import TranscriptionResults from "./components/TranscriptionResults";
import TranscriptionSettingsModal from "./components/TranscriptionSettingsModal";

import "react-h5-audio-player/lib/styles.css";
import "./App.css";

enum Actions {
  IMPORT_FILE = "IMPORT_FILE",
  CLEAR_FILE = "CLEAR_FILE",
  START_PROCESS_FILE = "START_PROCESS_FILE",
  STOP_PROCESS_FILE_SUCCESS = "STOP_PROCESS_FILE_SUCCESS",
  STOP_PROCESS_FILE = "STOP_PROCESS_FILE",
  CHANGE_TRANSCRIPTION_SETTINGS = "CHANGE_TRANSCRIPTION_SETTINGS",
}

type State = {
  file: null | File;
  transcription: {
    captions: Array<{
      started_at: string;
      user: string;
      transcription: string;
    }>;
    text: string;
    execution_time: number;
    model_type: string;
    language: string;
  };
  importing: boolean;
  processing: boolean;
};

const reducer = (oldState: State, action: { type: Actions; payload?: any }) => {
  switch (action.type) {
    case Actions.IMPORT_FILE:
      return {
        ...oldState,
        file: action.payload.file,
      };
    case "CLEAR_FILE":
      return {
        ...oldState,
        file: null,
        importing: false,
        processing: false,
        transcription: {
          ...oldState.transcription,
          text: "",
          execution_time: 0,
          captions: [],
        },
      };
    case "START_PROCESS_FILE":
      return {
        ...oldState,
        processing: true,
        transcription: {
          ...oldState.transcription,
          text: "",
          execution_time: 0,
          captions: [],
        },
      };
    case "STOP_PROCESS_FILE_SUCCESS":
      return {
        ...oldState,
        transcription: {
          ...oldState.transcription,
          text: action.payload.transcription,
          execution_time: action.payload.execution_time,
          captions: action.payload.captions,
        },
      };
    case "CHANGE_TRANSCRIPTION_SETTINGS":
      return {
        ...oldState,
        transcription: {
          ...oldState.transcription,
          ...action.payload,
        },
      };
    case "STOP_PROCESS_FILE":
      return {
        ...oldState,
        processing: false,
      };
    default:
      return oldState;
  }
};

function App() {
  const API_URL = process.env.REACT_APP_API_URL;
  let [isOpen, setIsOpen] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    transcription: {
      captions: [],
      text: "",
      execution_time: 0,
      model_type: "base",
      language: "",
    },
    file: null,
    importing: false,
    processing: false,
  });

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    console.log(acceptedFiles);
    dispatch({
      type: Actions.IMPORT_FILE,
      payload: { file: acceptedFiles[0] },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "audio/*": [] },
    multiple: false,
  });

  const size = state.file
    ? filesize(state.file.size, { base: 2, standard: "jedec" })
    : 0;

  const handleProcess = async () => {
    try {
      setIsOpen(false);

      dispatch({ type: Actions.START_PROCESS_FILE });

      const formData = new FormData();
      formData.append("file", state.file);
      formData.append("model_type", state.transcription.model_type);
      formData.append("language", state.transcription.language);

      const { data } = await axios.post(
        `${API_URL}/api/transcribe`,
        formData
      );

      console.log(data);

      dispatch({
        type: Actions.STOP_PROCESS_FILE_SUCCESS,
        payload: {
          transcription: data.transcription,
          captions: data.captions,
          execution_time: data.execution_time,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: Actions.STOP_PROCESS_FILE });
    }
  };

  return (
    <>
      <div className="h-screen flex">
        <div className="w-96 border-r-2 p-4">
          {!!state.file ? (
            <div className="p-4" data-test="audio-player">
              <div className="mb-4 text-center">
                <p>{state.file.name}</p>
                <p className="text-xs font-semibold">{size as string}</p>
              </div>

              <div className="mb-4 text-center">
                <AudioPlayer
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  src={URL.createObjectURL(state.file)}
                />
              </div>

              <div className="flex space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 bg-red-700 hover:bg-red-600 focus:bg-red-800 text-white rounded w-1/2"
                  onClick={() => dispatch({ type: Actions.CLEAR_FILE })}
                  data-test="clear-file-button"
                >
                  Clear
                </button>

                <button
                  type="button"
                  className={cn(
                    "px-4 py-2 bg-indigo-700 hover:bg-indigo-600 focus:bg-indigo-800 text-white rounded w-1/2"
                  )}
                  onClick={() => setIsOpen(true)}
                  data-test="transcribe-file-button"
                >
                  {state.processing ? "Transcribing..." : "Transcribe"}
                </button>
              </div>
            </div>
          ) : (
            <div
              {...getRootProps()}
              className="border-dashed border-2 p-4 text-center cursor-pointer"
              data-test="audio-upload-form"
            >
              <div className="mb-4">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop a file here, or click to select a file</p>
                )}
              </div>

              <button className="px-4 py-2 bg-indigo-700 hover:bg-indigo-600 focus:bg-indigo-800 text-white rounded w-full">
                Upload
              </button>
            </div>
          )}
        </div>
        <div
          className="p-4 flex-1 overflow-y-auto"
          data-test="transcription-results-area"
        >
          <TranscriptionResults
            processing={state.processing}
            captions={state.transcription.captions}
            transcription={state.transcription.text}
            execution_time={state.transcription.execution_time}
            model_type={state.transcription.model_type}
            language={state.transcription.language}
          />
        </div>
      </div>

      <TranscriptionSettingsModal
        isOpen={isOpen}
        model_type={state.transcription.model_type}
        language={state.transcription.language}
        onClose={() => setIsOpen(false)}
        onSubmit={handleProcess}
        onChange={(value: Record<string, string>) =>
          dispatch({
            type: Actions.CHANGE_TRANSCRIPTION_SETTINGS,
            payload: value,
          })
        }
      />
    </>
  );
}

export default App;
