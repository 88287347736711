import React from "react";
import { Dialog } from "@headlessui/react";

import { MODEL_TYPES, LANGUAGES } from "../constants";
import SelectMenu from "./SelectMenu";

interface Props {
  isOpen: boolean;
  model_type: string;
  language: string;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (value: Record<string, string>) => void;
}

type Option = {
  text: string;
  value: string;
};

function TranscriptionSettingsModal({
  isOpen,
  model_type,
  language,
  onClose,
  onSubmit,
  onChange,
}: Props) {
  const selectedLanguage = LANGUAGES.find(
    (option) => option.value === language
  ) as Option;
  const selectedModel = MODEL_TYPES.find(
    (option) => option.value === model_type
  ) as Option;

  return (
    <Dialog
      className="relative z-50"
      open={isOpen}
      onClose={onClose}
      data-test="transcription-settings-modal"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm w-full rounded bg-white rounded shadow">
          <Dialog.Title className="font-bold text-xl p-4 border-b">
            Transcription Settings
          </Dialog.Title>

          <div className="p-4 border-b">
            <div className="mb-4">
              <SelectMenu
                label="Language"
                options={LANGUAGES}
                selected={selectedLanguage}
                onChange={(option) => {
                  onChange({ language: option.value });
                }}
              />
            </div>

            <div>
              <SelectMenu
                label="Model Type"
                options={MODEL_TYPES}
                selected={selectedModel}
                onChange={(option) => {
                  onChange({ model_type: option.value });
                }}
              />
            </div>
          </div>

          <div className="p-4 text-right">
            <button
              type="button"
              className="px-4 py-2 bg-red-700 hover:bg-red-600 focus:bg-red-800 text-white rounded"
              onClick={onClose}
              data-test="transcription-modal-cancel-button"
            >
              Cancel
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-600 focus:bg-indigo-800 text-white rounded ml-2"
              onClick={onSubmit}
              data-test="transcription-modal-continue-button"
            >
              Continue
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default TranscriptionSettingsModal;
